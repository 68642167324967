import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "./useLanguaje";

function ThankYou() {
  const { currentLanguage } = useLanguage();

  const texts = {
    es: {
      title: "¡Gracias por tu compra! 🎉",
      message1: "Gracias por confiar en FotoExamen.",
      message2: "Ahora puedes disfrutar de todas las funciones premium.",
      message3: "Configura tu IA personalizada y descubre cómo podemos ayudarte a mejorar tus estudios.",
      button: "Configurar mi IA"
    },
    en: {
      title: "Thank you for your purchase! 🎉",
      message1: "Thank you for trusting PhotoExamAI.",
      message2: "You can now enjoy all premium features.",
      message3: "Set up your personal AI and discover how we can help you improve your studies.",
      button: "Set up my AI"
    },
    it: {
      title: "Grazie per il tuo acquisto! 🎉",
      message1: "Grazie per aver scelto RisolviCompiti.",
      message2: "Ora puoi godere di tutte le funzioni premium.",
      message3: "Configura la tua IA personale e scopri come possiamo aiutarti a migliorare i tuoi studi.",
      button: "Configura la mia IA"
    }
  };

  useEffect(() => {
    if (window.plausible) {
      window.plausible("Trial Started");
    }

    if (window.trackdesk) {
      window.trackdesk("fotoexamen", "conversion", {
        "conversionType": "freetrial"
      });
    }

    /* const userEmail = localStorage.getItem("email");
    if (userEmail) {
      if (typeof window.datafast === "function") {
        console.log("Calling datafast with email:", userEmail);
        window.datafast("payment", { email: userEmail });
      } else {
        console.warn("datafast function not available on window object");
      }
    } */
  }, []);

  return (
    <main className="max-w-6xl mx-auto mt-12 px-4 text-gray-800">
      <div className="bg-white rounded-xl p-8 md:p-12">
        <h1 className="text-3xl lg:text-4xl font-bold text-center mb-6 text-primary">
          {texts[currentLanguage].title}
        </h1>
        
        <div className="space-y-6 text-center">
          <p className="text-xl text-gray-600">
            {texts[currentLanguage].message1}
          </p>
          <p className="text-xl text-gray-600">
            {texts[currentLanguage].message2}
          </p>
          <p className="text-xl text-gray-600 mb-8">
            {texts[currentLanguage].message3}
          </p>
          
          <div className="flex justify-center">
            <Link 
              to={currentLanguage === "es" ? "/bienvenida" : 
                  currentLanguage === "it" ? "/benvenuto" : "/welcome"} 
              className="btn btn-primary px-8 py-3 text-lg font-semibold rounded-full transition-all hover:scale-105 hover:shadow-lg"
            >
              {texts[currentLanguage].button}
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ThankYou;
