import React, { useState } from "react";
import { Toaster, toast } from "react-hot-toast";

/* const questions = [
  {
    type: "multiple_choice",
    question: "¿Qué significa etimológicamente la palabra 'filosofía'?",
    options: [
      "Amor por la verdad",
      "Amor por la sabiduría",
      "Búsqueda de la verdad",
      "Búsqueda del conocimiento",
    ],
    answer: "Amor por la sabiduría",
  },
  {
    type: "true_false",
    question: "La filosofía nació en Roma.",
    options: ["Verdadero", "Falso"],
    answer: "Falso",
  },
  {
    type: "multiple_choice",
    question: "¿Dónde y cuándo se considera que nació la filosofía?",
    options: [
      "Atenas, siglo V a.C.",
      "Roma, siglo I a.C.",
      "Jonia, siglo VI a.C.",
      "Egipto, siglo III a.C.",
    ],
    answer: "Jonia, siglo VI a.C.",
  },
  {
    type: "multiple_choice",
    question:
      "¿Qué caracteriza al pensamiento racional (logos) en comparación con los mitos?",
    options: [
      "Explicaciones fantásticas",
      "Relatos tradicionales",
      "Explicaciones basadas en principios o leyes",
      "Uso de personajes legendarios",
    ],
    answer: "Explicaciones basadas en principios o leyes",
  },
  {
    type: "multiple_choice",
    question: "¿Qué filósofo fue el primero en llamarse a sí mismo 'filósofo'?",
    options: ["Sócrates", "Pitágoras", "Platón", "Aristóteles"],
    answer: "Pitágoras",
  },
  {
    type: "true_false",
    question: "Los mitos se basan en la observación de la naturaleza.",
    options: ["Verdadero", "Falso"],
    answer: "Verdadero",
  },
  {
    type: "open_ended",
    question:
      "¿Cómo definió Francis Macdonald Cornford la relación entre el mito y la filosofía?",
    answer: "Como dos formas sucesivas y complementarias de conocimiento.",
  },
  {
    type: "fill_in_the_blanks",
    question: "La primera escuela filosófica cristiana medieval fue la _____.",
    answer: "Patrística",
  },
  {
    type: "matching",
    question: "Empareja las siguientes filosofías con sus características.",
    pairs: [
      { left: "Racionalismo", right: "Superioridad de la razón" },
      { left: "Empirismo", right: "Conocimiento a través de los sentidos" },
      { left: "Existencialismo", right: "Libertad y desamparo humano" },
    ],
    answer: [
      { left: "Racionalismo", right: "Superioridad de la razón" },
      { left: "Empirismo", right: "Conocimiento a través de los sentidos" },
      { left: "Existencialismo", right: "Libertad y desamparo humano" },
    ],
  },
  {
    type: "ordering",
    question: "Ordena cronológicamente las siguientes filosofías.",
    options: [
      "Filosofía Antigua",
      "Filosofía Medieval",
      "Filosofía Moderna",
      "Filosofía Contemporánea",
    ],
    answer: [
      "Filosofía Antigua",
      "Filosofía Medieval",
      "Filosofía Moderna",
      "Filosofía Contemporánea",
    ],
  },
]; */

import stringSimilarity from "string-similarity";
import { useLanguage } from "./useLanguaje";

const texts = {
  es: {
    encouragementMessages: [
      "¡Sigue intentándolo!",
      "¡Lo estás haciendo bien!",
      "¡Excelente trabajo!",
      "¡Eres un genio!",
    ],
    correct: "¡Correcto! 🎉",
    incorrect: "Incorrecto 😢",
    quizTitle: "Quiz de FotoExamen",
    points: "Puntos",
    submit: "Enviar",
    selectOption: "Selecciona una opción",
    previous: "Anterior",
    next: "Siguiente",
    finish: "Finalizar",
    examSummary: "Resumen del examen",
    yourAnswer: "Tu respuesta",
    correctAnswer: "Respuesta correcta",
    suggestedAnswer: "Respuesta sugerida",
    finalScore: "Puntuación final",
    repeatQuiz: "Repetir Quiz",
  },
  en: {
    encouragementMessages: [
      "Keep trying!",
      "You're doing well!",
      "Excellent work!",
      "You're a genius!",
    ],
    correct: "Correct! 🎉",
    incorrect: "Incorrect 😢",
    quizTitle: "PhotoExamAI Quiz",
    points: "Points",
    submit: "Submit",
    selectOption: "Select an option",
    previous: "Previous",
    next: "Next",
    finish: "Finish",
    examSummary: "Exam Summary",
    yourAnswer: "Your answer",
    correctAnswer: "Correct answer",
    suggestedAnswer: "Suggested answer",
    finalScore: "Final score",
    repeatQuiz: "Repeat Quiz",
  },
  it: {
    encouragementMessages: [
      "Continua a provare!",
      "Stai facendo bene!",
      "Ottimo lavoro!",
      "Sei un genio!",
    ],
    correct: "Corretto! 🎉",
    incorrect: "Incorretto 😢",
    quizTitle: "Quiz di RisolviCompiti",
    points: "Punti",
    submit: "Invia",
    selectOption: "Seleziona un'opzione",
    previous: "Precedente",
    next: "Successivo",
    finish: "Fine",
    examSummary: "Riepilogo dell'esame",
    yourAnswer: "La tua risposta",
    correctAnswer: "Risposta corretta",
    suggestedAnswer: "Risposta suggerita",
    finalScore: "Punteggio finale",
    repeatQuiz: "Ripeti Quiz",
  },
};

const getEncouragementMessage = (score, currentLanguage) => {
  if (score < 3) return texts[currentLanguage].encouragementMessages[0];
  if (score < 6) return texts[currentLanguage].encouragementMessages[1];
  if (score < 9) return texts[currentLanguage].encouragementMessages[2];
  return texts[currentLanguage].encouragementMessages[3];
};

const Quiz = ({ questions }) => {
  const { currentLanguage } = useLanguage();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [responses, setResponses] = useState({});
  const [score, setScore] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [matchPairs, setMatchPairs] = useState([]);
  const [orderResponse, setOrderResponse] = useState([]);

  const handleAnswer = (answer) => {
    const correctAnswer = questions[currentQuestion].answer;
    let updatedScore = score;
    let isCorrect = false;

    if (questions[currentQuestion].type === "open_ended") {
      const similarity = stringSimilarity.compareTwoStrings(
        answer || "",
        correctAnswer || ""
      );
      isCorrect = similarity > 0.7;
    } else {
      isCorrect = JSON.stringify(answer) === JSON.stringify(correctAnswer);
    }

    if (isCorrect) {
      if (!questions[currentQuestion].answered) {
        updatedScore++;
        questions[currentQuestion].answered = true;
      }
      toast.success(texts[currentLanguage].correct);
    } else if (!isCorrect) {
      toast.error(texts[currentLanguage].incorrect);
    }

    setResponses({
      ...responses,
      [currentQuestion]: answer,
    });
    setScore(updatedScore);
  };

  const nextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResults(true);
    }
  };

  const prevQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleReset = () => {
    setCurrentQuestion(0);
    setResponses({});
    setScore(0);
    setShowResults(false);
  };

  const handleMatchChange = (left, right) => {
    const updatedPairs = matchPairs.filter((pair) => pair.left !== left);
    setMatchPairs([...updatedPairs, { left, right }]);
  };

  const handleOrderChange = (index, value) => {
    const updatedOrder = [...orderResponse];
    updatedOrder[index] = value;
    setOrderResponse(updatedOrder);
  };

  return (
    <div className="container mx-auto p-4 my-8">
      <Toaster />
      <div className="flex flex-col sm:flex-row justify-between items-center mb-8">
        <h1 className="text-2xl sm:text-4xl font-bold mb-4 sm:mb-0">{texts[currentLanguage].quizTitle}</h1>
        <div className="p-2 sm:p-4 bg-gray-100 rounded-lg shadow-sm">
          {texts[currentLanguage].points}: {score} - {getEncouragementMessage(score, currentLanguage)}
        </div>
      </div>
      {!showResults ? (
        <div>
          <div className="card shadow-lg compact bg-base-100 mb-4">
            <div className="card-body">
              <h2 className="card-title text-lg sm:text-xl">
                {currentQuestion + 1 + ". "}
                {questions[currentQuestion].question}
              </h2>
              {questions[currentQuestion].type === "multiple_choice" ? (
                questions[currentQuestion].options.map((option, i) => (
                  <div key={i} className="form-control">
                    <label
                      className={`cursor-pointer label ${
                        responses[currentQuestion] === option
                          ? "text-green-500"
                          : ""
                      }`}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name={`question-${currentQuestion}`}
                          value={option}
                          checked={responses[currentQuestion] === option}
                          onChange={() => handleAnswer(option)}
                          className="radio radio-primary"
                        />
                        <span className="label-text ml-4 sm:ml-8">{option}</span>
                      </div>
                    </label>
                  </div>
                ))
              ) : questions[currentQuestion].type === "true_false" ? (
                questions[currentQuestion].options.map((option, i) => (
                  <div key={i} className="form-control">
                    <label
                      className={`cursor-pointer label ${
                        responses[currentQuestion] === option
                          ? "text-green-500"
                          : ""
                      }`}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name={`question-${currentQuestion}`}
                          value={option}
                          checked={responses[currentQuestion] === option}
                          onChange={() => handleAnswer(option)}
                          className="radio radio-primary"
                        />
                        <span className="label-text ml-4 sm:ml-8">{option}</span>
                      </div>
                    </label>
                  </div>
                ))
              ) : questions[currentQuestion].type === "open_ended" ? (
                <div className="form-control">
                  <textarea
                    className="textarea textarea-bordered w-full"
                    placeholder="Escribe tu respuesta aquí..."
                    value={responses[currentQuestion] || ""}
                    onChange={(e) =>
                      setResponses({
                        ...responses,
                        [currentQuestion]: e.target.value,
                      })
                    }
                  />
                  <button
                    onClick={() => handleAnswer(responses[currentQuestion])}
                    className="btn btn-primary mt-2 w-full sm:w-auto"
                  >
                    {texts[currentLanguage].submit}
                  </button>
                </div>
              ) : questions[currentQuestion].type === "fill_in_the_blanks" ? (
                <div className="form-control">
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    placeholder="Escribe tu respuesta aquí..."
                    value={responses[currentQuestion] || ""}
                    onChange={(e) =>
                      setResponses({
                        ...responses,
                        [currentQuestion]: e.target.value,
                      })
                    }
                  />
                  <button
                    onClick={() => handleAnswer(responses[currentQuestion])}
                    className="btn btn-primary mt-2 w-full sm:w-auto"
                  >
                    {texts[currentLanguage].submit}
                  </button>
                </div>
              ) : questions[currentQuestion].type === "matching" ? (
                <div className="form-control">
                  {questions[currentQuestion].pairs.map((pair, i) => (
                    <div key={i} className="mb-2">
                      <label className="label flex flex-col sm:flex-row items-start sm:items-center">
                        <span className="label-text mb-2 sm:mb-0 sm:mr-2">{pair.left}</span>
                        <select
                          className="select select-bordered w-full sm:w-auto"
                          onChange={(e) =>
                            handleMatchChange(pair.left, e.target.value)
                          }
                        >
                          <option value="">{texts[currentLanguage].selectOption}</option>
                          {questions[currentQuestion].pairs.map((p, j) => (
                            <option key={j} value={p.right}>
                              {p.right}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                  ))}
                  <button
                    onClick={() => handleAnswer(matchPairs)}
                    className="btn btn-primary mt-2 w-full sm:w-auto"
                  >
                    {texts[currentLanguage].submit}
                  </button>
                </div>
              ) : questions[currentQuestion].type === "ordering" ? (
                <div className="form-control">
                  {questions[currentQuestion].options.map((option, i) => (
                    <div key={i} className="mb-2">
                      <label className="label flex flex-col sm:flex-row items-start sm:items-center">
                        <span className="label-text mb-2 sm:mb-0 sm:mr-2">{i + 1}</span>
                        <select
                          className="select select-bordered w-full sm:w-auto"
                          onChange={(e) => handleOrderChange(i, e.target.value)}
                        >
                          <option value="">{texts[currentLanguage].selectOption}</option>
                          {questions[currentQuestion].options.map((opt, j) => (
                            <option key={j} value={opt}>
                              {opt}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                  ))}
                  <button
                    onClick={() => handleAnswer(orderResponse)}
                    className="btn btn-primary mt-2 w-full sm:w-auto"
                  >
                    {texts[currentLanguage].submit}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex justify-between">
            <button
              onClick={prevQuestion}
              className="btn btn-primary w-1/2 sm:w-auto"
              disabled={currentQuestion === 0}
            >
              {texts[currentLanguage].previous}
            </button>
            <button
              onClick={nextQuestion}
              className={`btn btn-primary w-1/2 sm:w-auto ${
                currentQuestion === questions.length - 1 ? "btn-neutral" : ""
              }`}
            >
              {currentQuestion === questions.length - 1
                ? texts[currentLanguage].finish
                : texts[currentLanguage].next}
            </button>
          </div>
        </div>
      ) : (
        <div className="mt-8">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4">{texts[currentLanguage].examSummary}</h2>
          {questions.map((question, index) => (
            <div
              key={index}
              className="card shadow-lg compact bg-base-100 mb-4"
            >
              <div className="card-body">
                <h2
                  className={
                    JSON.stringify(responses[index]) ===
                      JSON.stringify(question.answer) ||
                    (question.type === "open_ended" &&
                      stringSimilarity.compareTwoStrings(
                        responses[index] || "",
                        question.answer || ""
                      ) > 0.7)
                      ? "card-title text-green-500 text-lg sm:text-xl"
                      : "card-title text-red-500 text-lg sm:text-xl"
                  }
                >
                  {index + 1 + ". "} {question.question}
                </h2>
                {question.type === "multiple_choice" ||
                question.type === "true_false" ? (
                  <p className="text-base sm:text-lg">
                    {texts[currentLanguage].yourAnswer}: {responses[index]}
                    <br />
                    {texts[currentLanguage].correctAnswer}: {question.answer}
                  </p>
                ) : question.type === "matching" ? (
                  <div className="text-base sm:text-lg">
                    {responses[index] &&
                      responses[index].map((pair, i) => (
                        <div key={i}>
                          {pair.left} - {pair.right}
                        </div>
                      ))}
                    <br />
                    {texts[currentLanguage].correctAnswer}:
                    {question.answer.map((pair, i) => (
                      <div key={i}>
                        {pair.left} - {pair.right}
                      </div>
                    ))}
                  </div>
                ) : question.type === "ordering" ? (
                  <div className="text-base sm:text-lg">
                    {texts[currentLanguage].yourAnswer}:
                    <ul>
                      {responses[index] &&
                        responses[index].map((item, i) => (
                          <li key={i}>{item}</li>
                        ))}
                    </ul>
                    {texts[currentLanguage].correctAnswer}:
                    <ul>
                      {question.answer.map((item, i) => (
                        <li key={i}>{item}</li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p className="text-base sm:text-lg">
                    {texts[currentLanguage].yourAnswer}: {responses[index]}
                    <br />
                    {texts[currentLanguage].suggestedAnswer}: {question.answer}
                  </p>
                )}
                {JSON.stringify(responses[index]) ===
                  JSON.stringify(question.answer) ||
                (question.type === "open_ended" &&
                  stringSimilarity.compareTwoStrings(
                    responses[index] || "",
                    question.answer || ""
                  ) > 0.7) ? (
                  <p className="text-green-500">{texts[currentLanguage].correct}</p>
                ) : (
                  <p className="text-red-500">{texts[currentLanguage].incorrect}</p>
                )}
              </div>
            </div>
          ))}
          <p className="text-xl font-bold">{texts[currentLanguage].finalScore}: {score}</p>
          <button onClick={handleReset} className="btn btn-primary mt-4 w-full sm:w-auto">
            {texts[currentLanguage].repeatQuiz}
          </button>
        </div>
      )}
    </div>
  );
};

export default Quiz;
