import React from "react";
import { useSubscription } from "./SubscriptionContext";
import { useLanguage } from "./useLanguaje";

export default function Footer() {
  const { subscriptionType } = useSubscription();
  const { currentLanguage } = useLanguage();

  const texts = {
    es: {
      quickLinks: "Enlaces rápidos",
      home: "Inicio",
      homeUrl: "https://www.fotoexamen.com",
      contact: "Contacto",
      contactUrl: "https://www.fotoexamen.com/contacto.html",
      prices: "Precios",
      pricesUrl: "https://www.fotoexamen.com/precios.html",
      legal: "Legal",
      privacyPolicy: "Políticas de privacidad",
      privacyPolicyUrl: "https://www.fotoexamen.com/politica-de-datos-y-privacidad.html",
      termsOfUse: "Condiciones de uso",
      termsOfUseUrl: "https://www.fotoexamen.com/condiciones-de-uso.html",
      legalNotice: "Aviso legal",
      legalNoticeUrl: "https://www.fotoexamen.com/aviso-legal.html",
      cookiesPolicy: "Política de Cookies",
      cookiesPolicyUrl: "https://www.fotoexamen.com/politica-de-cookies.html",
      payments: "Pagos",
      paymentsUrl: "https://www.fotoexamen.com/pagos.html",
      followUs: "Síguenos",
      twitter: "Twitter",
      twitterUrl: "https://twitter.com/fotoexamen",
      tiktok: "Tiktok",
      tiktokUrl: "https://www.tiktok.com/@fotoexamen",
      instagram: "Instagram",
      instagramUrl: "https://www.instagram.com/fotoexamen",
      contactUs: "Contacto",
      email: "info@fotoexamen.com",
      leaveReview: "⭐️ Déjanos tu valoración",
      close: "Cerrar",
      copyright: "© 2024 FotoExamen. Todos los derechos reservados.",
    },
    en: {
      quickLinks: "Quick Links",
      home: "Home",
      homeUrl: "https://www.photoexamai.com",
      contact: "Contact",
      contactUrl: "https://www.photoexamai.com/contact.html",
      prices: "Prices",
      pricesUrl: "https://www.photoexamai.com/prices.html",
      legal: "Legal",
      privacyPolicy: "Privacy Policies",
      privacyPolicyUrl: "https://www.photoexamai.com/data-and-privacy-policy.html",
      termsOfUse: "Terms of Use",
      termsOfUseUrl: "https://www.photoexamai.com/terms-of-use.html",
      legalNotice: "Legal Notice",
      legalNoticeUrl: "https://www.photoexamai.com/legal-notice.html",
      cookiesPolicy: "Cookies Policy",
      cookiesPolicyUrl: "https://www.photoexamai.com/cookies-policy.html",
      payments: "Payments",
      paymentsUrl: "https://www.photoexamai.com/payments.html",
      followUs: "Follow Us",
      twitter: "Twitter",
      twitterUrl: "https://twitter.com/fotoexamen",
      tiktok: "Tiktok",
      tiktokUrl: "https://www.tiktok.com/@fotoexamen",
      instagram: "Instagram",
      instagramUrl: "https://www.instagram.com/fotoexamen",
      contactUs: "Contact",
      email: "info@fotoexamen.com",
      leaveReview: "⭐️ Leave Us Your Review",
      close: "Close",
      copyright: "© 2024 PhotoExamAI. All rights reserved.",
    },
    it: {
      quickLinks: "Link Rapidi",
      home: "Home",
      homeUrl: "https://www.risolvicompiti.it",
      contact: "Contatto",
      contactUrl: "https://www.risolvicompiti.it/contatto.html",
      prices: "Prezzi",
      pricesUrl: "https://www.risolvicompiti.it/prezzi.html",
      legal: "Legale",
      privacyPolicy: "Informativa sulla privacy",
      privacyPolicyUrl: "https://www.risolvicompiti.it/informativa-sulla-privacy.html",
      termsOfUse: "Termini di utilizzo",
      termsOfUseUrl: "https://www.risolvicompiti.it/termini-di-utilizzo.html",
      legalNotice: "Note legali",
      legalNoticeUrl: "https://www.risolvicompiti.it/note-legali.html",
      cookiesPolicy: "Informativa sui cookie",
      cookiesPolicyUrl: "https://www.risolvicompiti.it/informativa-sui-cookie.html",
      payments: "Pagamenti",
      paymentsUrl: "https://www.risolvicompiti.it/pagamenti.html",
      followUs: "Seguici",
      twitter: "Twitter",
      twitterUrl: "https://twitter.com/fotoexamen",
      tiktok: "Tiktok",
      tiktokUrl: "https://www.tiktok.com/@fotoexamen",
      instagram: "Instagram",
      instagramUrl: "https://www.instagram.com/fotoexamen",
      contactUs: "Contatto",
      email: "info@risolvicompiti.it", // Changed email address
      leaveReview: "⭐️ Lasciaci la tua recensione",
      close: "Chiudi",
      copyright: "© 2024 RisolviCompiti. Tutti i diritti riservati.",
    },
  };

  const currentTexts = texts[currentLanguage];

  return (
    <>
      <footer className="bg-neutral text-white p-10">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
          <div className="footer-section">
            <h3 className="text-xl font-semibold mb-4">{currentTexts.quickLinks}</h3>
            <ul>
              <li className="my-2 hover:underline">
                <a href={currentTexts.homeUrl}>{currentTexts.home}</a>
              </li>
              <li className="my-2 hover:underline">
                <a href={currentTexts.contactUrl}>{currentTexts.contact}</a>
              </li>
              <li className="my-2 hover:underline">
                <a href={currentTexts.pricesUrl} title={currentTexts.prices}>
                  {currentTexts.prices}
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h3 className="text-xl font-semibold mb-4">{currentTexts.legal}</h3>
            <ul>
              <li className="my-2 hover:underline">
                <a href={currentTexts.privacyPolicyUrl}>
                  {currentTexts.privacyPolicy}
                </a>
              </li>
              <li className="my-2 hover:underline">
                <a href={currentTexts.termsOfUseUrl}>{currentTexts.termsOfUse}</a>
              </li>
              <li className="my-2 hover:underline">
                <a href={currentTexts.legalNoticeUrl}>{currentTexts.legalNotice}</a>
              </li>
              <li className="my-2 hover:underline">
                <a href={currentTexts.cookiesPolicyUrl}>{currentTexts.cookiesPolicy}</a>
              </li>
              <li className="my-2 hover:underline">
                <a href={currentTexts.paymentsUrl} title={currentTexts.payments}>
                  {currentTexts.payments}
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h3 className="text-xl font-semibold mb-4">{currentTexts.followUs}</h3>
            <div className="social-icons flex flex-col">
              <a
                href={currentTexts.twitterUrl}
                rel="noreferrer"
                target="_blank"
                title={currentTexts.twitter}
                className="hover:text-blue-400 transition-colors duration-300"
              >
                <i className="fab fa-twitter"></i>
                {currentTexts.twitter}
              </a>
              <a
                href={currentTexts.tiktokUrl}
                rel="noreferrer"
                target="_blank"
                title={currentTexts.tiktok}
                className="hover:text-blue-400 transition-colors duration-300"
              >
                <i className="fab fa-tiktok"></i>
                {currentTexts.tiktok}
              </a>
              <a
                href={currentTexts.instagramUrl}
                rel="noreferrer"
                target="_blank"
                title={currentTexts.instagram}
                className="hover:text-blue-400 transition-colors duration-300"
              >
                <i className="fab fa-instagram"></i>
                {currentTexts.instagram}
              </a>
            </div>
          </div>
          <div className="footer-section">
            <h3 className="text-xl font-semibold mb-4">{currentTexts.contactUs}</h3>
            <p className="mb-4">
              <a
                href={`mailto:${currentTexts.email}`}
                rel="noreferrer"
                className="underline hover:text-blue-400 transition-colors duration-300"
                target="_blank"
                title="Email"
              >
                {currentTexts.email}
              </a>
            </p>
            {subscriptionType !== "No Subscription" && (
              <button
                className="btn btn-accent"
                onClick={() => document.getElementById("my_modal_3").showModal()}
              >
                {currentTexts.leaveReview}
              </button>
            )}
            <dialog id="my_modal_3" className="modal">
              <div className="modal-box">
                <form method="dialog">
                  <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-black">
                    ✕
                  </button>
                </form>
                <script
                  type="text/javascript"
                  src="https://widget.senja.io/js/iframeResizer.min.js"
                ></script>
                <iframe
                  id="senja-collector-iframe"
                  src={
                    currentLanguage === "en"
                      ? "https://senja.io/p/foto-examen/r/XQSTBa?mode=embed&nostyle=true"
                      : "https://senja.io/p/foto-examen/r/5pPUbj?mode=embed&nostyle=true"
                  }
                  allow="camera;microphone"
                  title="Senja form"
                  frameBorder="0"
                  scrolling="no"
                  width="100%"
                  height="700"
                ></iframe>
                <form method="dialog">
                  <button className="btn btn-outline text-black">
                    {currentTexts.close}
                  </button>
                </form>
              </div>
            </dialog>
          </div>
        </div>
        <div className="copyright mt-12 text-center text-white">
          {currentTexts.copyright}
        </div>
      </footer>
    </>
  );
}
