import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CurrentPath from "./CurrentPath.js";
import MarkdownPreview from "./MarkdownPreview.js";
import SEONotetaker from "./seo/SEONotetaker.js";
import { useSubscription } from "./SubscriptionContext.js";
import useAuth from "./useAuthToken.js";
import { useLanguage } from "./useLanguaje";
import useMobile from "./useMobile.js";

const apiUrl = process.env.REACT_APP_API_URL;

const NoteTaker = ({ setShowNoTokenModal }) => {
  const { currentLanguage } = useLanguage();
  const [audio, setAudio] = useState(null);
  const [transcription, setTranscription] = useState("");
  const [notes, setNotes] = useState("");
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isGeneratingNotes, setIsGeneratingNotes] = useState(false);
  const { subscriptionType, refreshSubscription, remainingCredits } = useSubscription();
  const [token] = useAuth();
  const mobile = useMobile();


  const texts = {
    es: {
      documentTitle: "Tomar Apuntes con IA | FotoExamen - Transcripción y Generación de Notas Inteligente",
      metaDescription: "Transcribe audio y genera apuntes automáticamente con nuestra IA. FotoExamen te ofrece una herramienta avanzada para tomar notas eficientes en clases, conferencias y reuniones.",
      resetTitle: "FotoExamen",
      currentPath: "Tomar Apuntes",
      header: "📝 Tomar Apuntes con Inteligencia Artificial",
      uploadPrompt: "Sube tu archivo de audio de tu clase, transcríbelo y genera apuntes concisos utilizando nuestra inteligencia artificial avanzada.",
      uploadSectionTitle: "Sube tu archivo de audio:",
      uploadButton: "Transcribir Audio",
      transcribing: "Transcribiendo audio...",
      transcriptionTitle: "Transcripción:",
      generateNotesButton: "Generar Apuntes",
      generatingNotes: "Generando apuntes...",
      notesTitle: "Apuntes generados:",
      downloadingPDF: "Descargando PDF...",
      downloadPDFButton: "Descargar PDF",
      noAudioAlert: "Por favor, sube un archivo de audio válido.",
      premiumAlert: "Necesitas una suscripción premium pro para usar Tomar Apuntes.",
    },
    en: {
      documentTitle: "AI Note Taking | PhotoExamAI - Intelligent Transcription and Note Generation",
      metaDescription: "Transcribe audio and automatically generate notes with our AI. PhotoExamAI offers you an advanced tool to take efficient notes in classes, conferences, and meetings.",
      resetTitle: "PhotoExamAI",
      currentPath: "Take Notes",
      header: "📝 Note Taking",
      uploadPrompt: "Upload your class audio file, transcribe it, and generate concise notes using our advanced artificial intelligence.",
      uploadSectionTitle: "Upload your audio file:",
      uploadButton: "Transcribe Audio",
      transcribing: "Transcribing audio...",
      transcriptionTitle: "Transcription:",
      generateNotesButton: "Generate Notes",
      generatingNotes: "Generating notes...",
      notesTitle: "Generated Notes:",
      downloadingPDF: "Downloading PDF...",
      downloadPDFButton: "Download PDF",
      noAudioAlert: "Please upload a valid audio file.",
      premiumAlert: "You need a Pro premium subscription to use Note Taker.",
    },
    it: {
      documentTitle: "Appunti con IA | RisolviCompiti - Trascrizione e Generazione di Appunti Intelligente",
      metaDescription: "Trascrivi audio e genera appunti automaticamente con la nostra IA. RisolviCompiti ti offre uno strumento avanzato per prendere appunti in modo efficiente durante lezioni, conferenze e riunioni.",
      resetTitle: "RisolviCompiti",
      currentPath: "Prendi Appunti",
      header: "📝 Prendi Appunti con Intelligenza Artificiale",
      uploadPrompt: "Carica il tuo file audio della lezione, trascrivilo e genera appunti concisi utilizzando la nostra intelligenza artificiale avanzata.",
      uploadSectionTitle: "Carica il tuo file audio:",
      uploadButton: "Trascrivi Audio",
      transcribing: "Trascrizione in corso...",
      transcriptionTitle: "Trascrizione:",
      generateNotesButton: "Genera Appunti",
      generatingNotes: "Generazione appunti in corso...",
      notesTitle: "Appunti generati:",
      downloadingPDF: "Download PDF in corso...",
      downloadPDFButton: "Scarica PDF",
      noAudioAlert: "Si prega di caricare un file audio valido.",
      premiumAlert: "Hai bisogno di un abbonamento premium pro per utilizzare Prendi Appunti.",
    }
  };

  const handleAudioChange = (e) => {
    if (!token) {
      setShowNoTokenModal(true);
      return;
    }

    if (!["Pro"]?.includes(subscriptionType)) {
      alert(texts[currentLanguage].premiumAlert);
      return;
    }

    if (subscriptionType?.includes("Trial") && remainingCredits === 0) {
      document.getElementById('trial_ended_modal').showModal();
      return;
    }

    const file = e.target.files[0];
    if (file && file.type.startsWith("audio/")) {
      setAudio(file);
    } else {
      alert(texts[currentLanguage].noAudioAlert);
    }
  };

  const transcribeAudio = async () => {
    setIsTranscribing(true);
    if (!token) {
      setShowNoTokenModal(true);
      setIsTranscribing(false);
      return;
    }
    if (!audio) {
      alert(texts[currentLanguage].noAudioAlert);
      setIsTranscribing(false);
      return;
    }

    const readerFile = new FileReader();
    readerFile.onload = async (e) => {
      const base64Data = e.target.result.split(",")[1];
      try {
        const response = await fetch(`${apiUrl}/api/easyexams/transcribe`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            token: token,
            files: { audio: base64Data }
          })
        });
        if (!response.ok) {
          alert("Error al transcribir el audio.");
          setIsTranscribing(false);
          return;
        }
        // If the response is stream-based, read it:
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let output = "";
        while (true) {
          const { done, value } = await reader.read();
          if (value) {
            output += decoder.decode(value, { stream: !done });
            setTranscription(output);
          }
          if (done) break;
        }
        refreshSubscription();
        setIsTranscribing(false);
      } catch (error) {
        console.error("Error during transcription fetch:", error);
        setIsTranscribing(false);
      }
    };

    readerFile.readAsDataURL(audio);
  };

  const generateNotes = async () => {
    setIsGeneratingNotes(true);
    if (!token) {
      setIsGeneratingNotes(false);
      return;
    }
    try {
      const response = await fetch(`${apiUrl}/api/easyexams/generate_notes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ token, transcription }),
      });
      if (!response.ok) {
        alert("Error al generar los apuntes.");
        setIsGeneratingNotes(false);
        return;
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let output = "";
      while (true) {
        const { done, value } = await reader.read();
        if (value) {
          output += decoder.decode(value, { stream: !done });
          setNotes(output);
        }
        if (done) break;
      }
      refreshSubscription();
    } catch (error) {
      console.error("Error generating notes:", error);
    }
    setIsGeneratingNotes(false);
  };
  
  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-4">
      <Helmet>
        <title>{texts[currentLanguage].documentTitle}</title>
        <meta name="description" content={texts[currentLanguage].metaDescription} />
      </Helmet>
      <CurrentPath text={texts[currentLanguage].currentPath} />
      <div className="container max-w-4xl mx-auto p-8 bg-white rounded">
        <div className="mb-8 text-center max-w-xl mx-auto">
          <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2">
            {texts[currentLanguage].header}
          </h1>
          <p>
            {texts[currentLanguage].uploadPrompt}
          </p>
        </div>

        <div className="mb-8 space-y-6">
          <h2 className="text-2xl font-bold mb-4">{texts[currentLanguage].uploadSectionTitle}</h2>
          <div className="flex justify-center items-center">
            <input
              type="file"
              accept="audio/*"
              className="file-input w-full max-w-xs"
              onChange={handleAudioChange}
            />
          </div>
        </div>

        {audio && (
          <div className="mb-8">
            <audio controls className="w-full">
              <source src={URL.createObjectURL(audio)} type={audio.type} />
              {currentLanguage === 'es' ? "Tu navegador no soporta el elemento de audio." : "Your browser does not support the audio element."}
            </audio>
          </div>
        )}

        <div className="mb-8">
          <button
            className={`btn btn-primary ${
              !audio || isTranscribing ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={transcribeAudio}
            disabled={!audio || isTranscribing}
          >
            <FontAwesomeIcon icon={faMicrophone} className="mr-2" />
            {texts[currentLanguage].uploadButton}
          </button>
        </div>

        {isTranscribing && (
          <div className="flex flex-col justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 my-4"></div>
            <p className="text-lg font-semibold">{texts[currentLanguage].transcribing}</p>
          </div>
        )}

        {transcription && (
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">{texts[currentLanguage].transcriptionTitle}</h2>
            <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line">
              {transcription}
            </div>
          </div>
        )}

        {transcription && (
          <div className="mb-8">
            <button
              className={`btn btn-primary ${
                isGeneratingNotes || isTranscribing
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              onClick={generateNotes}
              disabled={isGeneratingNotes || isTranscribing}
            >
              {texts[currentLanguage].generateNotesButton}
            </button>
          </div>
        )}

        {isGeneratingNotes && (
          <div className="flex flex-col justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 my-4"></div>
            <p className="text-lg font-semibold">{texts[currentLanguage].generatingNotes}</p>
          </div>
        )}

        {notes && (
          <div className="mt-8">
            <h2 className="text-2xl font-bold mb-4">{texts[currentLanguage].notesTitle}</h2>
            {/* {isDownloading ? (
                <div className="flex flex-col justify-center items-center mb-2 mt-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-red-600"></div>
                  <p className="text-sm font-semibold mt-2">{texts[currentLanguage].downloadingPDF}</p>
                </div>
              ) : (
                <button
                  onClick={() => {
                    setIsDownloading(true);
                    downloadPDF(notesData, currentLanguage).finally(() => setIsDownloading(false));
                  }}
                  className="btn btn-primary mt-4"
                >
                  <FaFilePdf size={24} className="mr-2" /> {texts[currentLanguage].downloadPDFButton}
                </button>
              )} */}
            <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line">
              <MarkdownPreview content={notes}/>
            </div>
          </div>
        )}
      </div>
      {!token && !mobile && <SEONotetaker />}
    </div>
  );
};

export default NoteTaker;
