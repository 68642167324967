//External imports
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { useEffect, useRef } from "react";
import { useLanguage } from "./useLanguaje";
import useMobile from './useMobile';

const APIURL = `${process.env.REACT_APP_API_URL}/api/easyexams`; // "http://localhost/api/easyexams"

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });

const GoogleAuthComponent = () => {
  const googleButton = useRef(null);
  const {currentLanguage} = useLanguage();
  const mobile = useMobile();

  useEffect(() => {
    if (mobile) {
      GoogleAuth.initialize({
        clientId: "666271911245-2arhcfo5pr1fnftfvbp65qp6stcd15je.apps.googleusercontent.com",
        scopes: ["profile", "email"],
        grantOfflineAccess: true,
      }).catch(console.error);
    } else {
      const src = "https://accounts.google.com/gsi/client";
      const id = "666271911245-2arhcfo5pr1fnftfvbp65qp6stcd15je.apps.googleusercontent.com";

      loadScript(src)
        .then(() => {
          /*global google*/
          console.log(google);
          google.accounts.id.initialize({
            client_id: id,
            callback: handleCredentialResponse,
          });
          google.accounts.id.renderButton(googleButton.current, {
            theme: "outline",
            size: "large",
            locale: currentLanguage,
          });
        })
        .catch(console.error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobile, currentLanguage]);

  async function handleCredentialResponse(response) {
    function loginGoogle(body) {
      const urlParams = new URLSearchParams(window.location.search);
      const clientReferenceId = localStorage.getItem('client_reference_id') || urlParams.get('client_reference_id');
      if (clientReferenceId) {
        body.client_reference_id = clientReferenceId;
      }
      return fetch(APIURL + "/logingoogle", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
    }

    let credential;
    if (mobile) {
      try {
        const googleUser = await GoogleAuth.signIn();
        credential = googleUser.authentication.idToken;
        console.log("Mobile Google credential:", credential);
      } catch (error) {
        console.error("Error during Google sign-in:", error);
      }
    } else {
      console.log("Encoded JWT ID token: " + response.credential);
      credential = response.credential;
    }

    try {
      loginGoogle({
        googleToken: credential,
        lang: currentLanguage
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          //if (!data?.token) window.location.href = "https://www.fotoexamen.com/precios.html";

          if (data?.token) localStorage.setItem("token", data?.token);
          if (data?.email) localStorage.setItem("email", data?.email);
          if (data?.profileImage)
            localStorage.setItem("picture", data?.profileImage);
          if (data?.token) {
            if (window.plausible) {
              window.plausible("Login Google");
            }
            fetch(APIURL + "/mailsuscribe", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ email: data?.email }),
            })
              .then((response) => response.json())
              .then((data) => console.log("Respuesta de mi API:", data))
              .then(
                () =>
                  (window.location.href =
                    "/?token=" + data?.token)
              )
              .catch((error) =>
                console.error("Error al comunicarse con mi API:", error)
              );
          }
        })
        .catch(() => {
          //window.location.href = "https://www.fotoexamen.com/precios.html";
        });
    } catch (error) {
      console.error("Network error during authentication:", error);
    }
  }

  return mobile ? (
    <button onClick={handleCredentialResponse} className="btn btn-outline">
      Sign in with Google
    </button>
  ) : (
    <div ref={googleButton}></div>
  );
};

export default GoogleAuthComponent;
