import { select } from "d3-selection";
import { Transformer } from "markmap-lib";
import { Toolbar } from "markmap-toolbar";
import "markmap-toolbar/dist/style.css";
import { Markmap } from "markmap-view";
import * as pdfjsLib from "pdfjs-dist/webpack";
import React, { useEffect, useRef, useState } from "react";
import CurrentPath from "./CurrentPath";
import SEOMarkmap from "./seo/SEOMarkmap";
import { useSubscription } from "./SubscriptionContext";
import useAuth from "./useAuthToken";
import { useLanguage } from "./useLanguaje";
import useMobile from "./useMobile";

const apiUrl = process.env.REACT_APP_API_URL;

const MarkmapSchemaCreator = ({ setShowNoTokenModal }) => {
  const [files, setFiles] = useState([]);
  const [markmapData, setMarkmapData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const transformer = new Transformer();
  const { subscriptionType, remainingCredits } = useSubscription();
  const [token ] = useAuth();
  const mobile = useMobile();

  const markmapRef = useRef(null);
  const svgRef = useRef(null);
  const toolbarRef = useRef(null);

  const { currentLanguage } = useLanguage();

const texts = {
  es: {
    title: "Creador de Mapas Conceptuales con IA | FotoExamen - Visualiza tus Ideas",
    metaDescription: "Crea mapas conceptuales interactivos y esquemas a partir de tus documentos con nuestra IA. FotoExamen te ofrece una herramienta potente para visualizar y organizar información compleja de forma clara y estructurada.",
    alertNoFile: "Por favor, selecciona al menos un archivo.",
    alertNoToken: "No authentication token found.",
    alertNoSubscription: "Necesitas una suscripción de Pro o Standard para acceder a los mapas conceptuales.",
    errorLargeFile: "Error: el archivo es demasiado grande, máximo 45 páginas",
    errorProcessing: "Error procesando el mapa conceptual. Por favor, intenta de nuevo.",
    downloadError: "SVG element not found for download.",
    downloadFileNameSuffix: "-markmap.svg",
    buttonGenerate: "Generar Mapa Conceptual",
    buttonDownload: "Descargar Mapa",
    headerTitle: "🗺️ Creador de Mapas Conceptuales Interactivos",
    description: "Transforma tus documentos en mapas mentales interactivos",
    descriptionDetail: "Sube los archivos de los cuales quieras obtener un esquema, ya sea en formato PDF o varias imágenes, y conviértelos automáticamente en esquemas claros y estructurados. Ideal para visualizar conceptos complejos, organizar ideas y mejorar tu proceso de aprendizaje. <strong>Máximo pdfs con 45 páginas</strong>",
    conceptualMapTitle: "Mapa Conceptual",
    path: "Creador de Mapas Conceptuales"
  },
  it: {
    title: "Creatore di Mappe Concettuali con IA | RisolviCompiti - Visualizza le Tue Idee",
    metaDescription: "Crea mappe concettuali interattive e schemi a partire dai tuoi documenti con la nostra IA. RisolviCompiti ti offre uno strumento potente per visualizzare e organizzare informazioni complesse in modo chiaro e strutturato.",
    alertNoFile: "Per favore, seleziona almeno un file.",
    alertNoToken: "No authentication token found.",
    alertNoSubscription: "Hai bisogno di un abbonamento Pro o Standard per accedere alle mappe concettuali.",
    errorLargeFile: "Errore: il file è troppo grande, massimo 45 pagine",
    errorProcessing: "Errore durante l'elaborazione della mappa concettuale. Per favore, riprova.",
    downloadError: "Elemento SVG non trovato per il download.",
    downloadFileNameSuffix: "-markmap.svg",
    buttonGenerate: "Genera Mappa Concettuale",
    buttonDownload: "Scarica Mappa",
    headerTitle: "🗺️ Creatore di Mappe Concettuali Interattive",
    description: "Trasforma i tuoi documenti in mappe mentali interattive",
    descriptionDetail: "Carica i file da cui desideri ottenere uno schema, sia in formato PDF che più immagini, e converti automaticamente in schemi chiari e strutturati. Ideale per visualizzare concetti complessi, organizzare idee e migliorare il tuo processo di apprendimento. <strong>PDF massimi con 45 pagine</strong>",
    conceptualMapTitle: "Mappa Concettuale",
    path: "Creatore di Mappe Concettuali"
  },
  en: {
    title: "Conceptual Map Creator with AI | PhotoExamAI - Visualize Your Ideas",
    metaDescription: "Create interactive conceptual maps and diagrams from your documents with our AI. PhotoExamAI offers you a powerful tool to visualize and organize complex information clearly and structured.",
    alertNoFile: "Please select at least one file.",
    alertNoToken: "No authentication token found.",
    alertNoSubscription: "You need a Pro or Standard subscription to access conceptual maps.",
    errorLargeFile: "Error: the file is too large, maximum 45 pages",
    errorProcessing: "Error processing the conceptual map. Please try again.",
    downloadError: "SVG element not found for download.",
    downloadFileNameSuffix: "-markmap.svg",
    buttonGenerate: "Generate Conceptual Map",
    buttonDownload: "Download Map",
    headerTitle: "🗺️ Interactive Conceptual Map Creator",
    path: "Conceptual Map Creator",
    description: "Transform your documents into interactive mind maps",
    descriptionDetail: "Upload the files from which you want to get a diagram, whether in PDF format or multiple images, and automatically convert them into clear and structured diagrams. Ideal for visualizing complex concepts, organizing ideas, and improving your learning process. <strong>Maximum PDFs with 45 pages</strong>",
    conceptualMapTitle: "Conceptual Map"
  }
};


  useEffect(() => {
    document.title = texts[currentLanguage].title;

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', texts[currentLanguage].metaDescription);
    }

    return () => {
      document.title = "FotoExamen";
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  useEffect(() => {
    if (markmapData && svgRef.current) {
      try {
        svgRef.current.innerHTML = "";
        const svg = select(svgRef.current)
          .attr("width", "100%")
          .attr("height", "100%")
          .attr(
            "viewBox",
            `0 0 ${svgRef.current.clientWidth} ${svgRef.current.clientHeight}`
          )
          .style("background-color", "white");

        const { root } = transformer.transform(
          markmapData.replace(/^```(markmap)?\n?|\n```$/gm, "").trim()
        );
        const mm = Markmap.create(svg.node(), { fit: true }, root);

        mm.fit();

        if (toolbarRef.current) {
          toolbarRef.current.innerHTML = "";
          const toolbar = new Toolbar();
          toolbar.attach(mm);
          toolbar.setItems([...Toolbar.defaultItems]);
          toolbarRef.current.append(toolbar.render());
        }
      } catch (error) {
        console.error("Error processing Markmap data:", error);
        setErrorMessage(texts[currentLanguage].errorProcessing);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markmapData, currentLanguage]);

  const handleFilesChange = (e) => {
    setFiles([...e.target.files]);
  };


  const handleSubmit = async () => {
    if (files.length === 0) {
      alert(texts[currentLanguage].alertNoFile);
      return;
    }

    if (!token) {
      setShowNoTokenModal(true);
      console.error(texts[currentLanguage].alertNoToken);
      setIsLoading(false);
      return;
    }

    if (subscriptionType === "No Subscription") {
      setShowNoTokenModal(true);
      return;
    }

    if (subscriptionType?.includes("Trial") && remainingCredits === 0) {
      document.getElementById('trial_ended_modal').showModal();
      return;
    }

    if (!subscriptionType?.includes("Standard") && !subscriptionType?.includes("Pro")) {
      alert(texts[currentLanguage].alertNoSubscription);
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    const filePromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve({ name: file.name, data: reader.result });
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    try {
      const fileDataArray = await Promise.all(filePromises);
      let textContent = "";

      if (files[0].type === "application/pdf") {
        const pdf = await pdfjsLib.getDocument(URL.createObjectURL(files[0]))
          .promise;

        if (pdf.numPages > 45) {
          setIsLoading(false);
          setErrorMessage(texts[currentLanguage].errorLargeFile);
          return;
        }

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const text = await page.getTextContent();
          // eslint-disable-next-line no-loop-func
          text.items.forEach((item) => {
            textContent += item.str + " ";
          });
        }
      }

      const response = await fetch(`${apiUrl}/api/easyexams/markmap`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token, files: fileDataArray, textContent })
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let output = "";
      let accumulatedData = "";
      let updateCount = 0;
      while (true) {
        const { done, value } = await reader.read();
        if (value) {
          accumulatedData += decoder.decode(value, { stream: !done });
          updateCount++;
          if (updateCount >= 40) {
            output = accumulatedData;
            setMarkmapData(output);
            updateCount = 0;
          }
        }
        if (done) {
          if (updateCount > 0) {
            setMarkmapData(accumulatedData);
          }
          break;
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error sending files:", error);
      setErrorMessage(texts[currentLanguage].errorLargeFile);
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    const svgElement = svgRef.current;
    if (svgElement) {
      const serializer = new XMLSerializer();
      let source = serializer.serializeToString(svgElement);

      if (!source.match(/^<svg[^>]+xmlns="http:\/\/www.w3.org\/2000\/svg"/)) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns="http://www.w3.org/2000/svg"'
        );
      }
      if (!source.match(/^<svg[^>]+"http:\/\/www.w3.org\/1999\/xlink"/)) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
        );
      }

      const svg64 = btoa(unescape(encodeURIComponent(source)));
      const image64 = "data:image/svg+xml;base64," + svg64;

      const element = document.createElement("a");
      element.href = image64;
      element.download = files[0].name.split(".")[0] + texts[currentLanguage].downloadFileNameSuffix;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else {
      console.error(texts[currentLanguage].downloadError);
    }
  };

  return (
    <>
      <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-4">
        <CurrentPath text={texts[currentLanguage].path} />
        <div className="container max-w-4xl mx-auto p-0 mt-8 sm:p-8 rounded">
          <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
            {texts[currentLanguage].headerTitle}
          </h1>

          <div className="text-center mb-6 p-4 bg-blue-100 border-l-4 border-blue-500 text-blue-700">
            <p className="font-semibold">
              {texts[currentLanguage].description}
            </p>
            <p dangerouslySetInnerHTML={{ __html: texts[currentLanguage].descriptionDetail }} />
          </div>

          <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <input
              type="file"
              accept="image/png, image/jpg, image/jpeg, image/gif, image/webp, application/pdf"
              multiple
              onChange={handleFilesChange}
              className="file-input file-input-bordered w-full"
            />
            <button
              onClick={handleSubmit}
              className="btn btn-primary w-full sm:w-auto"
              disabled={isLoading}
            >
              <span className="mr-2">{texts[currentLanguage].buttonGenerate}</span>
              {isLoading && (
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
              )}
            </button>
          </div>

          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </div>
      </div>
      <div className="w-full p-8 shadow-lg">
        {markmapData && (
          <>
            <h2 className="text-2xl font-bold border-b-4 border-white text-center">
              {texts[currentLanguage].conceptualMapTitle}
            </h2>
            <button
              onClick={handleDownload}
              className="btn btn-primary"
            >
              {texts[currentLanguage].buttonDownload}
            </button>
            <div
              ref={markmapRef}
              style={{
                width: "100%",
                height: "80vh",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <svg ref={svgRef}></svg>
              <div className="absolute top-1 right-1" ref={toolbarRef}></div>
            </div>
          </>
        )}
        {!token && !mobile && <SEOMarkmap />}
      </div>
    </>
  );
};

export default MarkmapSchemaCreator;
