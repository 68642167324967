import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "./useAuthToken";
import { useLanguage } from "./useLanguaje";

const apiUrl = process.env.REACT_APP_API_URL;

export default function Precios() {
  const { currentLanguage } = useLanguage();
  const [activeTab, setActiveTab] = useState("monthly");
  const [email, setEmail] = useState("");
  const [token] = useAuth();
  const [trakdeskClientId, setTrakdeskClientId] = useState(null);

  const navigate = useNavigate();

  const texts = {
    es: {
      monthly: "Mensual",
      annual: "🔥 Anual: 6+ meses gratis",
      basic: "Plan Básico",
      standard: "Plan Standard",
      pro: "Plan Pro",
      iaBasic: "IA NIVEL BASICA",
      iaMedium: "IA NIVEL MEDIA",
      iaAdvanced: "IA NIVEL AVANZADO",
      unlimitedUse: "USO SIN LÍMITES",
      featuresBasic: [
        "✔ Acceso a la extensión de Chrome",
        "✔ Resolver y corregir exámenes o tareas",
        "✔ Escritor de ensayos",
        "✔ Chatear con los Tutores IA",
        "✔ Resumir textos",
        "✔ Subrayar documentos",
        "✔ Historial completo de examenes",
        "❌ Esquemas conceptuales",
        "❌ Quizzes",
        "❌ Tomar apuntes",
      ],
      featuresStandard: [
        "✔ Acceso a la extensión de Chrome",
        "✔ Resolver y corregir exámenes o tareas",
        "✔ Escritor de ensayos",
        "✔ Chatear con los Tutores IA",
        "✔ Resumir textos",
        "✔ Subrayar documentos",
        "✔ Historial completo de examenes",
        "✔ Esquemas conceptuales",
        "✔ Quizzes",
        "✔ Más velocidad de procesamiento",
        "❌ Tomar apuntes",
        "❌ Acceso anticipado a nuevas funciones",
      ],
      featuresPro: [
        "✔ Acceso a la extensión de Chrome",
        "✔ Resolver y corregir exámenes o tareas",
        "✔ Escritor de ensayos",
        "✔ Chatear con los Tutores IA",
        "✔ Resumir textos",
        "✔ Subrayar documentos",
        "✔ Historial completo de examenes",
        "✔ Esquemas conceptuales",
        "✔ Quizzes",
        "✔ Más velocidad de procesamiento",
        "✔ Tomar apuntes",
        "✔ Acceso anticipado a nuevas funciones",
      ],
      freeTrial: "Prueba gratis de 3 días ➤",
      freeTrialDesc:
        "Disfruta de mejoras constantes y nuestra IA sin límites. Prueba gratis de 3 días.",
    },
    en: {
      monthly: "Monthly",
      annual: "🔥 Annual: 6+ months free",
      basic: "Basic Plan",
      standard: "Standard Plan",
      pro: "Pro Plan",
      iaBasic: "AI BASIC LEVEL",
      iaMedium: "AI MEDIUM LEVEL",
      iaAdvanced: "AI ADVANCED LEVEL",
      unlimitedUse: "UNLIMITED USE",
      featuresBasic: [
        "✔ Access to the Chrome extension",
        "✔ Solve and correct exams or assignments",
        "✔ Essay writer",
        "✔ Chat with AI Tutors",
        "✔ Summarize texts",
        "✔ Highlight documents",
        "✔ Complete exam history",
        "❌ Conceptual maps",
        "❌ Quizzes",
        "❌ Take notes",
      ],
      featuresStandard: [
        "✔ Access to the Chrome extension",
        "✔ Solve and correct exams or assignments",
        "✔ Essay writer",
        "✔ Chat with AI Tutors",
        "✔ Summarize texts",
        "✔ Highlight documents",
        "✔ Complete exam history",
        "✔ Conceptual maps",
        "✔ Quizzes",
        "✔ Faster processing speed",
        "❌ Take notes",
        "❌ Early access to new features",
      ],
      featuresPro: [
        "✔ Access to the Chrome extension",
        "✔ Solve and correct exams or assignments",
        "✔ Essay writer",
        "✔ Chat with AI Tutors",
        "✔ Summarize texts",
        "✔ Highlight documents",
        "✔ Complete exam history",
        "✔ Conceptual maps",
        "✔ Quizzes",
        "✔ Faster processing speed",
        "✔ Take notes",
        "✔ Early access to new features",
      ],
      freeTrial: "Free 3-day Trial ➤",
      freeTrialDesc:
        "Enjoy constant improvements and our unlimited AI. Free 3-day trial.",
    },
    it: {
      monthly: "Mensile",
      annual: "🔥 Annuale: 6+ mesi gratis",
      basic: "Piano Base",
      standard: "Piano Standard",
      pro: "Piano Pro",
      iaBasic: "IA LIVELLO BASE",
      iaMedium: "IA LIVELLO MEDIO",
      iaAdvanced: "IA LIVELLO AVANZATO",
      unlimitedUse: "UTILIZZO ILLIMITATO",
      featuresBasic: [
        "✔ Accesso all'estensione di Chrome",
        "✔ Risolvere e correggere esami o compiti",
        "✔ Scrittore di saggi",
        "✔ Chatta con i tutor IA",
        "✔ Riepiloga testi",
        "✔ Evidenzia documenti",
        "✔ Cronologia completa degli esami",
        "❌ Mappe concettuali",
        "❌ Quiz",
        "❌ Prendi appunti",
      ],
      featuresStandard: [
        "✔ Accesso all'estensione di Chrome",
        "✔ Risolvere e correggere esami o compiti",
        "✔ Scrittore di saggi",
        "✔ Chatta con i tutor IA",
        "✔ Riepiloga testi",
        "✔ Evidenzia documenti",
        "✔ Cronologia completa degli esami",
        "✔ Mappe concettuali",
        "✔ Quiz",
        "✔ Velocità di elaborazione più elevata",
        "❌ Prendi appunti",
        "❌ Accesso anticipato a nuove funzionalità",
      ],
      featuresPro: [
        "✔ Accesso all'estensione di Chrome",
        "✔ Risolvere e correggere esami o compiti",
        "✔ Scrittore di saggi",
        "✔ Chatta con i tutor IA",
        "✔ Riepiloga testi",
        "✔ Evidenzia documenti",
        "✔ Cronologia completa degli esami",
        "✔ Mappe concettuali",
        "✔ Quiz",
        "✔ Velocità di elaborazione più elevata",
        "✔ Prendi appunti",
        "✔ Accesso anticipato a nuove funzionalità",
      ],
      freeTrial: "Prova gratuita di 3 giorni ➤",
      freeTrialDesc:
        "Goditi miglioramenti costanti e la nostra IA illimitata. Prova gratuita di 3 giorni.",
    },
  };

  // eslint-disable-next-line no-unused-vars
  const stripeLinks = {
    es: {
      monthly: {
        //basic: "https://buy.stripe.com/9AQ5lEaST3Le7tK3ce",
        basic: "https://buy.stripe.com/28obK2e555TmeWcfZh",
        medium: "https://buy.stripe.com/7sIaFYf996Xq3du8wz",
        pro: "https://buy.stripe.com/00g6pI3qr1D6aFW3cg",
      },
      annual: {
        //basic: "https://buy.stripe.com/dR68xQ5yzftW15maER",
        basic: "https://buy.stripe.com/8wM4hAaST81u9BScN6",
        medium: "https://buy.stripe.com/3cscO6aST0z201i9AO",
        pro: "https://buy.stripe.com/00geWebWXepS01i7sH",
      },
    },
    it: {
      monthly: {
        basic: "https://buy.stripe.com/14k7tMd110z2eWcdRd",
        medium: "https://buy.stripe.com/fZecO69OPgy015mfZn",
        pro: "https://buy.stripe.com/00g8xQ9OPa9CaFW00r",
      },
      annual: {
        basic: "https://buy.stripe.com/9AQ01kbWX5Tmg0gaF2",
        medium: "https://buy.stripe.com/7sI4hAe5595y4hy28y",
        pro: "https://buy.stripe.com/00g8xQ6CDbdG29q3cE",
      },
    },
    en: {
      monthly: {
        //basic: "https://buy.stripe.com/5kAaFY0efepSbK0cMR",
        basic: "https://buy.stripe.com/fZe8xQ8KL0z229qeVf",
        medium: "https://buy.stripe.com/aEU4hAf991D69BS3ci",
        pro: "https://buy.stripe.com/dR65lE8KL6XqeWcfZ5",
      },
      annual: {
        //basic: "https://buy.stripe.com/eVabK23qr81u15m6oE",
        basic: "https://buy.stripe.com/aEU5lEd11epS3du4gC",
        medium: "https://buy.stripe.com/cN29BU9OP95y01i5kB",
        pro: "https://buy.stripe.com/3cs5lE9OPchKbK05kC",
      },
    },
  };

  const prices = {
    es: {
      basic: activeTab === "monthly" ? "9€ / mes" : "4,5€ / mes",
      medium: activeTab === "monthly" ? "17€ / mes" : "8,5€ / mes",
      pro: activeTab === "monthly" ? "30€ / mes" : "15€ / mes",
    },
    it: {
      basic: activeTab === "monthly" ? "9€ / mes" : "4,5€ / mes",
      medium: activeTab === "monthly" ? "17€ / mes" : "8,5€ / mes",
      pro: activeTab === "monthly" ? "30€ / mes" : "15€ / mes",
    },
    en: {
      basic: activeTab === "monthly" ? "$11 / month" : "$5.5 / month",
      medium: activeTab === "monthly" ? "$21 / month" : "$10.5 / month",
      pro: activeTab === "monthly" ? "$42 / month" : "$21 / month",
    },
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (token === "" || !token) return;

      const response = await fetch(apiUrl + "/api/easyexams/checktoken", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const dataToken = await response.json();
      if (dataToken?.email) {
        localStorage.setItem("email", dataToken.email);
        setEmail(dataToken.email);
      }
    };
    fetchUserData();
  }, [token]);

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  useEffect(() => {
    const getTrakdeskClientId = () => {
      const cookie = document.cookie.match(
        "(^|;)\\s*trakdesk_cid\\s*=\\s*([^;]+)"
      );
      if (Array.isArray(cookie)) {
        try {
          const trakdeskCid = JSON.parse(cookie.pop());
          setTrakdeskClientId(trakdeskCid["cid"]);
        } catch (e) {
          console.log(e);
        }
      }
    };

    getTrakdeskClientId();
  }, []);

  const handleTrialClick = (e, plan) => {
    e.preventDefault();
    if (!token) {
      navigate(
        currentLanguage === "en"
          ? "/login"
          : currentLanguage === "it"
          ? "/accedi"
          : "/iniciar-sesion"
      );
    } else {
      /* const stripeUrl = stripeLinks[currentLanguage][activeTab][plan];
      const url = new URL(stripeUrl);
      url.searchParams.set("prefilled_email", email);
      if (trakdeskClientId) {
        url.searchParams.set("client_reference_id", trakdeskClientId);
      }
      console.log("URL stripe", url);
      window.open(url.href, "_blank"); */
    }
  };

  const renderStripeButton = (plan) => {
    if (!token) {
      return (
        <button
          className="btn btn-primary w-full"
          onClick={() =>
            navigate(
              currentLanguage === "en"
                ? "/login"
                : currentLanguage === "it"
                ? "/accedi"
                : "/iniciar-sesion"
            )
          }
        >
          {texts[currentLanguage].freeTrial}
        </button>
      );
    }

    const pid = {
      es: {
        "basic": "buy_btn_1Qbr2SAd57ZahgSrnodU4p37",
        "basicannual": "buy_btn_1QbrAnAd57ZahgSrV9Ebxz2L",
        "standard": "buy_btn_1QbrPhAd57ZahgSrp2kqPC9z",
        "standardannual": "buy_btn_1QbrSLAd57ZahgSrkbHa265A",
        "pro": "buy_btn_1QbrVMAd57ZahgSrMQdLyKAh",
        "proannual": "buy_btn_1QbrY2Ad57ZahgSr6bC08xGm"
      },
      it: {
        "basic": "buy_btn_1QbjiqAd57ZahgSr4JBNdq24",
        "basicannual": "buy_btn_1QbsPYAd57ZahgSrAudMJloj",
        "standard": "buy_btn_1QbZAMAd57ZahgSr35ZAYcyy",
        "standardannual": "buy_btn_1QbsSpAd57ZahgSrzpsV94ug",
        "pro": "buy_btn_1QbsTWAd57ZahgSrOcbgH4j3",
        "proannual": "buy_btn_1QbsU5Ad57ZahgSrdxb3G0gO"
      },
      en: {
        "basic": "buy_btn_1QbsWZAd57ZahgSr8fCktnBK",
        "basicannual": "buy_btn_1QbsYWAd57ZahgSre4nclivY",
        "standard": "buy_btn_1QbsZ6Ad57ZahgSrTEpYACob",
        "standardannual": "buy_btn_1QbsavAd57ZahgSrSQRgwNM8",
        "pro": "buy_btn_1QbsZkAd57ZahgSrBxwJnKmw",
        "proannual": "buy_btn_1QbsbXAd57ZahgSrcR1PEKD0"
      }
    };

    const buyButtonId = pid[currentLanguage][`${plan}${activeTab === "annual" ? "annual" : ""}`];

    return (
      <stripe-buy-button
        buy-button-id={buyButtonId}
        publishable-key="pk_live_nTItl8PAuOf3qiRNLnaWQzgM00j4atJk8C"
        customer-email={email}
        client-reference-id={trakdeskClientId}
        key={`${plan}-${activeTab}`} // Add key to force re-render on tab change
      ></stripe-buy-button>
    );
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center mt-8 mb-2">
          <button
            className={`mr-2 ${
              activeTab === "monthly" ? "btn btn-primary" : "btn"
            }`}
            onClick={() => setActiveTab("monthly")}
          >
            {texts[currentLanguage].monthly}
          </button>
          <button
            className={`${activeTab === "annual" ? "btn btn-primary" : "btn"}`}
            onClick={() => setActiveTab("annual")}
          >
            {texts[currentLanguage].annual}
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-stretch">
        {/* Basic Plan */}
        <div
          id="plan-card1"
          className="card bg-white p-6 shadow-lg rounded-lg flex flex-col justify-between my-8 hover:shadow-xl transition-shadow duration-300"
          onClick={(e) => handleTrialClick(e, "basic")}
        >
          <div>
            <div id="price1" className="text-3xl font-bold">
              {prices[currentLanguage].basic}
            </div>
            <h2 className="text-2xl font-bold mt-4 mb-2">
              {texts[currentLanguage].basic}
            </h2>
            <div className="badge badge-neutral mb-2 mr-2">
              {currentLanguage === "es"
                ? texts.es.iaBasic
                : currentLanguage === "en"
                ? texts.en.iaBasic
                : texts.it.iaBasic}
            </div>
            <div className="badge badge-neutral">
              {texts[currentLanguage].unlimitedUse}
            </div>
            <ul className="my-4 text-left">
              {(currentLanguage === "es"
                ? texts.es.featuresBasic
                : currentLanguage === "en"
                ? texts.en.featuresBasic
                : texts.it.featuresBasic
              ).map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
          <div className="mt-auto">
            {renderStripeButton("basic")}
            <div className="text-xs mt-4">
              {texts[currentLanguage].freeTrialDesc}
            </div>
          </div>
        </div>
        {/* Standard Plan */}
        <div
          id="plan-card2"
          className="card bg-white p-6 shadow-xl rounded-lg border-2 border-primary flex flex-col justify-between my-8 hover:shadow-2xl transition-shadow duration-300"
          onClick={(e) => handleTrialClick(e, "medium")}
        >
          <div>
            <div
              id="price2"
              className="text-3xl font-bold flex justify-between"
            >
              <div>{prices[currentLanguage].medium}</div>
              <span className="badge badge-primary text-white">
                {currentLanguage === "es" ? "Más popular" : "Most popular"}
              </span>
            </div>
            <h2 className="text-2xl font-bold mt-4 mb-2">
              {texts[currentLanguage].standard}
            </h2>
            <div className="badge badge-neutral mb-2 mr-2">
              {currentLanguage === "es"
                ? texts.es.iaMedium
                : currentLanguage === "en"
                ? texts.en.iaMedium
                : texts.it.iaMedium}
            </div>
            <div className="badge badge-neutral">
              {texts[currentLanguage].unlimitedUse}
            </div>
            <ul className="my-4 text-left">
              {(currentLanguage === "es"
                ? texts.es.featuresStandard
                : currentLanguage === "en"
                ? texts.en.featuresStandard
                : texts.it.featuresStandard
              ).map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
          <div className="mt-auto">
            {renderStripeButton("standard")}
            <div className="text-xs mt-4">
              {texts[currentLanguage].freeTrialDesc}
            </div>
          </div>
        </div>
        {/* Pro Plan */}
        <div
          id="plan-card3"
          className="card bg-white p-6 shadow-lg rounded-lg flex flex-col justify-between my-8 hover:shadow-xl transition-shadow duration-300"
          onClick={(e) => handleTrialClick(e, "pro")}
        >
          <div>
            <div id="price3" className="text-3xl font-bold">
              {prices[currentLanguage].pro}
            </div>
            <h2 className="text-2xl font-bold mt-4 mb-2">
              {texts[currentLanguage].pro}
            </h2>
            <div className="badge badge-neutral mb-2 mr-2">
              {currentLanguage === "es"
                ? texts.es.iaAdvanced
                : currentLanguage === "en"
                ? texts.en.iaAdvanced
                : texts.it.iaAdvanced}
            </div>
            <div className="badge badge-neutral">
              {texts[currentLanguage].unlimitedUse}
            </div>
            <ul className="my-4 text-left">
              {(currentLanguage === "es"
                ? texts.es.featuresPro
                : currentLanguage === "en"
                ? texts.en.featuresPro
                : texts.it.featuresPro
              ).map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
          <div className="mt-auto">
            {renderStripeButton("pro")}
            <div className="text-xs mt-4">
              {texts[currentLanguage].freeTrialDesc}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
