import * as pdfjsLib from "pdfjs-dist/webpack";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CurrentPath from "./CurrentPath";
import SEOHighlight from "./seo/SEOHighlight";
import { useSubscription } from "./SubscriptionContext";
import useAuth from "./useAuthToken";
import { useLanguage } from "./useLanguaje";
import useMobile from "./useMobile";


const apiUrl = process.env.REACT_APP_API_URL;

const Highlight = ({ setShowNoTokenModal }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [text, setText] = useState("");
  const [highlightedText, setHighlightedText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { subscriptionType, refreshSubscription, remainingCredits } = useSubscription();
  const [token] = useAuth();
  const mobile = useMobile();
  const { currentLanguage } = useLanguage();

  const texts = {
    es: {
      uploadPrompt: "Por favor, sube un archivo o escribe algún texto antes de subrayar.",
      noAuthToken: "No authentication token found.",
      noSubscription: "Necesitas una suscripción para usar el servicio.",
      trialEnded: "El periodo de prueba ha terminado.",
      unsupportedFile: "Tipo de archivo no admitido. Sube una imagen o un archivo PDF.",
      tooLong: "El texto es demasiado extenso. Por favor, sube otro archivo más pequeño o introduce menos texto. Número de tokens excedido has subido: ",
      errorFetching: "Error pruebe nuevamente o con otro archivo más pequeño. Si el problema persiste contacte con nosotros.",
      processing: "Procesando archivos...",
      highlightText: "Subrayar Texto",
      textPlaceholder: "Escribe aquí tu texto...",
      title: "📝 Subrayador de texto",
      description: "Sube un documento en PDF o varias imágenes o puedes introducir texto directamente para obtener un subrayado que te ayude a estudiar.",
      colorScheme: "Se usa el siguiente esquema de colores: verde para datos importantes, azul para definiciones y rojo para fechas clave.",
      subtitle: "Escribe un texto o sube un PDF",
      pageTitle: "Subrayador de Texto | FotoExamen - Mejora tu Estudio",
      pageDescription: "Utiliza nuestro subrayador de texto para identificar rápidamente las partes más importantes de tu material de estudio.",
      pdfTooLong: "El PDF tiene más de 50 páginas. Por favor, sube un archivo más pequeño.",
    },
    en: {
      uploadPrompt: "Please upload a file or write some text before highlighting.",
      noAuthToken: "No authentication token found.",
      noSubscription: "You need a subscription to use the service.",
      trialEnded: "The trial period has ended.",
      unsupportedFile: "Unsupported file type. Upload an image or a PDF file.",
      tooLong: "The text is too long. Please upload a smaller file or enter less text. Tokens exceeded: ",
      errorFetching: "Error, please try again or with a smaller file. If the problem persists, contact us.",
      processing: "Processing files...",
      highlightText: "Highlight Text",
      textPlaceholder: "Write your text here...",
      title: "📝 Text Highlighter",
      description: "Upload a PDF document or multiple images, or you can enter text directly to get a highlight that helps you study.",
      colorScheme: "The following color scheme is used: green for important data, blue for definitions, and red for key dates.",
      subtitle: "Write text or upload a PDF",
      pageTitle: "Text Highlighter | PhotoExamAI - Enhance Your Study",
      pageDescription: "Use our text highlighter to quickly identify the most important parts of your study material.",
      pdfTooLong: "The PDF has more than 50 pages. Please upload a smaller file.",
    },
    it: {
      uploadPrompt: "Per favore, carica un file o scrivi del testo prima di evidenziare.",
      noAuthToken: "Nessun token di autenticazione trovato.",
      noSubscription: "Hai bisogno di un abbonamento per utilizzare il servizio.",
      trialEnded: "Il periodo di prova è terminato.",
      unsupportedFile: "Tipo di file non supportato. Carica un'immagine o un file PDF.",
      tooLong: "Il testo è troppo lungo. Per favore, carica un file più piccolo o inserisci meno testo. Token superati: ",
      errorFetching: "Errore, riprova o con un file più piccolo. Se il problema persiste, contattaci.",
      processing: "Elaborazione file...",
      highlightText: "Evidenzia testo",
      textPlaceholder: "Scrivi qui il tuo testo...",
      title: "📝 Evidenziatore di testo",
      description: "Carica un documento PDF o più immagini, oppure puoi inserire direttamente il testo per ottenere un'evidenziazione che ti aiuti a studiare.",
      colorScheme: "Viene utilizzato il seguente schema di colori: verde per i dati importanti, blu per le definizioni e rosso per le date chiave.",
      subtitle: "Scrivi testo o carica un PDF",
      pageTitle: "Evidenziatore di Testo | RisolviCompiti - Migliora il Tuo Studio",
      pageDescription: "Usa il nostro evidenziatore di testo per identificare rapidamente le parti più importanti del tuo materiale di studio.",
      pdfTooLong: "Il PDF ha più di 50 pagine. Per favore, carica un file più piccolo.",
    }
  };

  const content = texts[currentLanguage] || texts.es;

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setText("");
    setHighlightedText("");

    if (file && file.type === "application/pdf") {
      try {
        const fileData = await file.arrayBuffer();
        const pdf = await pdfjsLib.getDocument(fileData).promise;
        if (pdf.numPages > 50) {
          alert(content.pdfTooLong);
          setSelectedFile(null);
          return;
        }
      } catch (error) {
        console.error("Error reading PDF:", error);
        alert(content.errorFetching);
        setSelectedFile(null);
        return;
      }
    }
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    setSelectedFile(null);
    setHighlightedText("");
  };

  const handleHighlight = async () => {
    if (!selectedFile && !text.trim()) {
      alert(content.uploadPrompt);
      return;
    }

    if (!token) {
      setShowNoTokenModal(true);
      console.error(content.noAuthToken);
      return;
    }

    if (subscriptionType === "No Subscription") {
      alert(content.noSubscription);
      return;
    }

    if (subscriptionType?.includes("Trial") && remainingCredits === 0) {
      document.getElementById('trial_ended_modal').showModal();
      return;
    }


    setIsLoading(true);
    setErrorMessage("");

    const data = {
      token: token,
      files: {},
      text: text || null
    };

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const base64Data = e.target.result.split(",")[1];
        if (selectedFile.type.startsWith("image/")) {
          data.files.image = base64Data;
        } else if (selectedFile.type === "application/pdf") {
          data.files.pdf = base64Data;
        } else {
          alert(content.unsupportedFile);
          setIsLoading(false);
          return;
        }
        try {
          const response = await fetch(`${apiUrl}/api/easyexams/highlight`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
          });
          const readerStream = response.body.getReader();
          const decoder = new TextDecoder();
          let output = "";
          while (true) {
            const { done, value } = await readerStream.read();
            if (value) {
              output += decoder.decode(value, { stream: !done });
              setHighlightedText(output);
            }
            if (done) break;
          }
          refreshSubscription();
          setIsLoading(false);
        } catch (error) {
          console.error("Error highlighting text:", error);
          alert(content.errorFetching);
          setIsLoading(false);
        }
      };
      reader.readAsDataURL(selectedFile);
    } else {
      try {
        const response = await fetch(`${apiUrl}/api/easyexams/highlight`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        });
        const readerStream = response.body.getReader();
        const decoder = new TextDecoder();
        let output = "";
        while (true) {
          const { done, value } = await readerStream.read();
          if (value) {
            output += decoder.decode(value, { stream: !done });
            setHighlightedText(output);
          }
          if (done) break;
        }
        refreshSubscription();
        setIsLoading(false);
      } catch (error) {
        console.error("Error highlighting text:", error);
        alert(content.errorFetching);
        setIsLoading(false);
      }
    }

  };


  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-4">
      <Helmet>
        <title>{content.pageTitle}</title>
        <meta name="description" content={content.pageDescription} />
      </Helmet>
      <CurrentPath text={content.title} />
      <div className="container max-w-4xl mx-auto p-0 mt-8 sm:p-8 rounded">
        <h1 className="text-4xl font-bold border-b-4 border-white pb-2 text-center">
          {content.title}
        </h1>
        <p>{content.description}</p>
        <p className="mb-6 mt-2">{content.colorScheme}</p>
        <h2 className="text-2xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
          {content.subtitle}
        </h2>
        <div>
          {!selectedFile && (
            <textarea
              className="textarea textarea-bordered h-24 w-full"
              placeholder={content.textPlaceholder}
              value={text}
              onChange={handleTextChange}
            ></textarea>
          )}

          {!text && (
            <input
              type="file"
              accept="image/png, image/jpg, image/jpeg, image/gif, image/webp, application/pdf"
              onChange={handleFileChange}
              className="file-input file-input-bordered w-full"
            />
          )}
        </div>

        <button
          onClick={handleHighlight}
          className="btn btn-primary w-full sm:w-auto mt-4"
          disabled={isLoading}
        >
          {content.highlightText}
        </button>

        {isLoading && (
          <div className="flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green-600 my-4"></div>
            <p className="text-lg font-semibold">{content.processing}</p>
          </div>
        )}

        {errorMessage && (
          <div className="shadow-lg mt-4 p-4 rounded">
            <div>
              <span>{errorMessage}</span>
            </div>
          </div>
        )}

        {highlightedText && (
          <div
            className="p-4 mt-4 rounded-box text-lg border border-base-300"
            dangerouslySetInnerHTML={{ __html: highlightedText }}
          ></div>
        )}
      </div>
      {!token && !mobile && <SEOHighlight />}
    </div>
  );
};

export default Highlight;
